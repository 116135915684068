<template>
  <div class="about">
  </div>
</template>
<script>
import {generalMixins} from "@/mixins/general";

export default {
  name: 'instagram',
  mixins: [generalMixins],
  created() {
    if (this.getMobileOperatingSystem() !== 'unknown') {
      window.location.href = 'instagram://user?username=merakispato';
    }
    else {
      window.open('https://www.instagram.com/merakispato');
    }
  }
}
</script>
