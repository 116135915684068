<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
import {generalMixins} from "@/mixins/general";

export default {
  name: 'facebook',
  mixins: [generalMixins],
  created() {
    if (this.getMobileOperatingSystem() !== 'unknown') {
      window.location.href = 'fb://page/104037701034249';
    }
    else {
      window.open('https://www.facebook.com/MerakiSpaTO');
    }
  }
}
</script>